import { useState, useRef, useEffect } from "react";
import { useResize } from "../hooks/useResize";

const Benefits = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const {windowSize} = useResize();
    const componentRef = useRef();

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const targetPosition = componentRef.current.offsetTop - 500;
        if (scrollPosition >= targetPosition) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="row mt-4 bg-light-purple pt-3" />
            <div className="row bg-purple-200 pt-3" />
            <div className="row bg-purple-300 pt-3" />

            <div ref={componentRef} className="row bg-green">
                <div className="col-11 mx-auto rounded-4 py-5 px-3">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <video className="d-block mx-auto rounded-3" width="100%" autoPlay controls>
                                <source src="https://storage.googleapis.com/npg-storage.appspot.com/landing/videos/Landing%20page.mp4" type="video/mp4" controls autoPlay />
                                Tu navegador no soporta el tag de video.
                            </video>
                        </div>
                        <div className={`col-12 col-lg-6 ${windowSize.width < 578 ? "mt-4": ""}`}>
                            <h3 className="text-white text-center">
                                <span className="font-lilita-one">Sortom</span> es la solución que te permite centrarte en lo que realmente importa: hacer crecer tu negocio <div className="paralelogramo mt-1"></div>
                            </h3>

                            <div className="row mt-5">
                                <div className={`col animate__animated ${isScrolled ? "animate__bounceInRight animate__delay-1s" : "d-none"}`}>
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="text-white">
                                    Haz documentos en minutos 
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className={`col animate__animated ${isScrolled ? "animate__bounceInRight animate__delay-2s" : "d-none"}`}>
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="text-white">
                                    Firma electrónicamente desde cualquier lugar
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className={`col animate__animated ${isScrolled ? "animate__bounceInRight animate__delay-3s" : "d-none"}`}>
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="text-white">
                                    Aumenta la seguridad y la eficiencia 
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className={`col animate__animated ${isScrolled ? "animate__bounceInRight animate__delay-4s" : "d-none"}`}>
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="text-white">
                                    Mejora la experiencia de tus clientes 
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className={`col animate__animated ${isScrolled ? "animate__bounceInRight animate__delay-5s" : "d-none"}`}>
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="text-white">
                                    Reduce errores 
                                    </span>
                                </div>
                            </div>
        
                            <div className="row mt-5">
                                <div className={`col animate__animated ${isScrolled ? "animate__bounceInRight animate__delay-7s" : "d-none"}`}>
                                    <h4 className="text-white text-center">¿Qué esperas para transformar tu negocio con SORTOM? CTA: ¡Prueba gratis! / Agenda un DEMO </h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row bg-light-green pt-3" />
            <div className="row bg-light-purple pt-3" />
        </>
    )
}

export default Benefits;