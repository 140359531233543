import { push, ref } from "firebase/database";
import { FirebaseDB } from "./config/firebaseConfig";

export const registerRequestClient = async ({ name, email, phone, subject, message }) => {
    try {
        const newRequestClient = {
            name,
            email,
            phone,
            subject,
            message,
            timestamp: Date.now(),
        };

        await push(ref(FirebaseDB, `request-clients`), newRequestClient);
        return { ok: true, message: "Mensaje enviado, te contactaremos muy pronto :)" };
    } catch (error) {
        console.log(error);
        return { ok: false, message: "No fue posible enviar tu mensaje :(" };
    }
}