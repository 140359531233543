import { createSlice } from '@reduxjs/toolkit';

export const planSlice = createSlice({
    name: 'planSlice',
    initialState: {
        selectedPlan: undefined,
    },
    reducers: {
        setSelectedPlan: (state, { payload }) => {
            state.selectedPlan = payload;
        },
    },
});

export const {
    setSelectedPlan,
} = planSlice.actions;

export default planSlice.reducer;