import CardFunctionality from "../common/CardFunctionality";

import FirmaElectronica from "../../assets/Firma_electronica.png";
// import ParametrizacionEficiente from "../../assets/Parametrizacion_eficiente.png";
import PlantillasPersonalizadas from "../../assets/Plantillas_personalizadas.png";
import DocumentosInteligentes from "../../assets/Documentos_inteligentes.png";
import Security from "../../assets/Security.png";
// import Crm from "../../assets/CRM.png";
// import ComunicacionEmpresarial from "../../assets/Comunicacion_empresarial.png";

const Functionalities = () => {
    return (
        <>
            <div className="row mt-3">
                <h1 className="d-block text-center text-green font-teko" id="functionalities">Funcionalidades</h1>
                <h5 className="d-block text-center text-green font-lato">Así de fácil y a pocos pasos</h5>
            </div>

            <div className="row my-3 section-functionality">
                <CardFunctionality
                    title="Firma electrónica"
                    subtitle="Tan legal como la tradicional"
                    description=" Olvídate de los largos procesos de impresión y escaneo, y firma documentos legalmente válidos de forma rápida y segura con solo unos clics."
                    src={FirmaElectronica}
                />
                {/* <CardFunctionality
                    title="Parametrización eficiente"
                    subtitle="Formatos personalizados para clientes"
                    description="Crea formularios automáticos adaptados a las necesidades de tu empresa. Obtén información de tus usuarios de manera rápida y sencilla con formularios dinámicos."
                    src={ParametrizacionEficiente}
                /> */}
                <CardFunctionality
                    title="Plantillas personalizadas"
                    subtitle="Crea documentos con ayuda de ChatGPT"
                    description="Optimiza tu flujo de trabajo con plantillas parametrizadas. Utiliza códigos y la inteligencia artificial para crear documentos de manera eficiente y sin complicaciones."
                    src={PlantillasPersonalizadas}
                />
                <CardFunctionality
                    title="Documentos personalizados"
                    subtitle="Exporta toda la información procesada y firma"
                    description="Simplifica tu trabajo con documentos adaptados a tus necesidades. Con Sortom, crea y personaliza fácilmente tus propios documentos, ahorrando tiempo y esfuerzo en la creación de formularios y contratos a medida para tu empresa."
                    src={DocumentosInteligentes}
                // className="pending"
                />
                {/* <CardFunctionality
                    title="CRM"
                    subtitle="Procesos más eficientes"
                    description="Administra tus clientes y procesos de manera eficiente con nuestro CRM integrado. Optimiza la relación con tus clientes y mejora tus estrategias de ventas."
                    src={Crm}
                    className="pending"
                />
                <CardFunctionality
                    title="Comunicación empresarial"
                    subtitle="Procesos más eficientes"
                    description="Facilita la comunicación entre los usuarios de tu empresa a través de nuestro chat empresarial. Comparte documentos, enlaces y colabora de manera efectiva."
                    src={ComunicacionEmpresarial}
                /> */}
                <CardFunctionality
                    title="Seguridad"
                    subtitle="Protección avanzada y control de acceso"
                    description="Asegura tus archivos con encriptación de alta calidad y establece roles específicos para controlar quién puede ver o editar cada documento. Mantén tus datos seguros y cumple con las normativas de protección de información."
                    src={Security}
                />

            </div>
        </>
    )
}

export default Functionalities;