import { Navbar, Nav } from "react-bootstrap";

import { useThrowAnimation } from "../hooks/useThrowAnimation";
import LogoSortom from "../../assets/logoSortom.png";

const CustomNavbar = () => {
    const { animation } = useThrowAnimation(5000, "animate__swing");

    return (
        <Navbar collapseOnSelect expand="md" className="px-2 d-flex w-100 bg-green" sticky="top">
            <Navbar.Brand>
                <img className="ps-2 me-2" src={LogoSortom} alt="Logo Sortom" height={30} />
                <span className="font-lilita-one text-white fs-4">Sortom</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto px-4">
                    <Nav.Link className="link-navbar text-white fw-semibold fs-6" href="/#home">Inicio</Nav.Link>
                    <Nav.Link className="link-navbar text-white fw-semibold fs-6" href="/#functionalities">Funcionalidades</Nav.Link>
                    {/* <Nav.Link className="link-navbar text-white fw-semibold fs-6" href="/#messaging">Mensajería</Nav.Link> */}
                    <Nav.Link className="link-navbar text-white fw-semibold fs-6" href="/#documents">Documentos</Nav.Link>
                    <Nav.Link className="link-navbar text-white fw-semibold fs-6" href="/#pricing">Precios</Nav.Link>
                    {/* <Nav.Link className="link-navbar text-white fw-semibold fs-6" href="/#faqs">FAQs</Nav.Link> */}
                    <Nav.Link
                        className={`d-block link-navbar text-purple text-center fw-semibold fs-6 bg-white px-3 ms-2 rounded-pill animate__animated ${animation}`}
                        href="/#contact-us"
                    >
                        Cóntactanos
                    </Nav.Link>
                    <Nav.Link className="link-navbar text-white fw-semibold fs-6 rounded-2 ms-2" style={{ border: "1px solid #fff" }} href="https://sortom.com" target="_blank">
                        <i className="fa-solid fa-bars-staggered me-1"></i>Ir al Panel
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default CustomNavbar;