import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer";
import Main from "../components/Main";
// import RegisterClient from "../components/RegisterClient";

const LandingPage = () => {
    return (
        <main>
            <div className="content">
                <div className="container-fluid p-0">
                    <CustomNavbar />
                    <Router>
                        <Switch>
                            <Route exact path="/"> <Main /> </Route>
                            {/* <Route exact path="/register-client"><RegisterClient /></Route> */}
                        </Switch>
                    </Router>

                    <Footer />
                </div>
            </div>
        </main>
    )
}

export default LandingPage;