
const CardQuestion = ({ name = "", active = false, onClick }) => {
    return (
        <div className={`row card-question ${active ? "active" : ""}`} onClick={onClick}>
            <div className="col-1">
                <i className="fa-solid fa-circle fs-5"></i>
            </div>
            <div className="col-9 text-question">
                {name}
            </div>
            <div className="col-2">
                <i className="fa-solid fa-chevron-right fs-5"></i>
            </div>
        </div>
    )
}

export default CardQuestion;