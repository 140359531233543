import { useEffect, useRef, useState } from "react";

import Chatbot from "../../assets/Chatbot.jpg";
import ChatColegas from "../../assets/ChatColegas.jpg";

const Messages = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const componentRef = useRef();

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const targetPosition = componentRef.current.offsetTop - 500;
        if (scrollPosition >= targetPosition) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div ref={componentRef}>
            <hr className="hr-style" />
            <hr className="hr-style" />

            <div className="row mt-3 mb-2">
                <h1 className="d-block text-center text-green font-teko" id="messaging">Mensajería</h1>
                <h5 className="d-block text-center text-green font-lato">Con miembros de tu compañía y con la IA</h5>
            </div>
            <div className="row">
                <div className="col-11 col-lg-9 mx-auto">
                    <div className="row">
                        <div className="col-5 col-lg-3 mx-auto abs-center">
                            <img src={Chatbot} alt="Chatea con el bot" width="100%" />
                        </div>
                        <div className="col-7 col-lg-9 mx-auto">
                            <div className={`message animate__animated animate__slow ${isScrolled ? "animate__bounceInRight" : ""}`}>
                                <h4 className="font-teko">Parametrizar formatos</h4>
                                <p className="description">Puedes crear formatos automatiables con ayuda de <b>chatGPT 4</b>, pedir recomendaciones, hacer redacciones y más.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-11 col-lg-8 mx-auto">
                    <div className="row">
                        <div className="col-7 col-lg-9 mx-auto">
                            <div className={`message left animate__animated animate__slow ${isScrolled ? "animate__bounceInLeft" : ""}`}>
                                <h4 className="font-teko">Mensajea con tus colegas</h4>
                                <p className="description">Puedes hablar con integrantes de tu empresa en tiempo real, para acordar reuniones, envío de documentos y de paso guardas la evidencia.</p>
                            </div>
                        </div>
                        <div className="col-5 col-lg-3 mx-auto abs-center">
                            <img src={ChatColegas} alt="Chatea con tus colegas" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messages;