import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useResize } from "../hooks/useResize";
import { useThrowAnimation } from "../hooks/useThrowAnimation";

import LogoSortom from "../../assets/logoSortom.png";
import Process from "../../assets/Process.png";

const Start = () => {
    const { animation } = useThrowAnimation(4000, "animate__rubberBand animate__delay-2s");
    const canvasRef = useRef(null);
    const { windowSize } = useResize();
    const history = useHistory();

    const drawBackground = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        ctx.beginPath();
        ctx.moveTo(0, 0);

        if (canvasWidth <= 576) ctx.lineTo(0, canvasHeight * .6);
        else ctx.lineTo(0, canvasHeight * .3);

        ctx.lineTo(100, (canvasHeight - 10));

        if (canvasWidth <= 576) ctx.lineTo(canvasWidth, canvasHeight * .8);
        else ctx.lineTo(canvasWidth, canvasHeight * .7);

        ctx.lineTo(canvasWidth, 0);
        ctx.fillStyle = '#198754';
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();

        if (canvasWidth <= 576) ctx.lineTo(0, canvasHeight * .63);
        else ctx.lineTo(0, canvasHeight * .35);

        ctx.lineTo(95, canvasHeight);

        if (canvasWidth <= 576) ctx.lineTo(canvasWidth, (canvasHeight * .8) + 8);
        else ctx.lineTo(canvasWidth, (canvasHeight * .7) + 8);

        ctx.lineTo(canvasWidth, 0);
        ctx.strokeStyle = '#8A83E7';
        ctx.stroke();
        ctx.closePath();
    }

    useEffect(() => {
        drawBackground();
    }, [windowSize.width]);

    return (
        <>
            <div style={{ position: "relative", width: "100%", height: "600px" }} id="home">
                <canvas ref={canvasRef} width={windowSize.width} height={600}></canvas>
                <div style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "600px" }}>

                    <div className="row mt-5">
                        <div className="col">
                            <div className="bench">
                                <img className="d-inline-block me-2" src={LogoSortom} alt="Logo Sortom" height={50} />
                                <h1 className="name-logo">Sortom</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-11 col-lg-6 mx-auto">
                            <p className="text-white text-center fs-5 font-lato">Sortom: Documentación instantánea, sin complicaciones</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-1 col-lg-2"></div>
                        <div className="col-7 col-md-4 col-lg-4 mx-auto">
                            <div className="row">
                                <div className="col">
                                    <a href="/#contact-us" className={`probar-gratis bg-white fs-5 rounded-pill animate__animated ${animation}`}>
                                        Contáctanos
                                    </a>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col animate__animated animate__bounceInLeft animate__delay-1s">
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="d-inline-block text-white">
                                        Documentos a un clic
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col animate__animated animate__bounceInLeft animate__delay-2s">
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="text-white">
                                        Firma electrónica
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col animate__animated animate__bounceInLeft animate__delay-3s">
                                    <div className="benefit-circle bg-white">
                                        <i className="fa-solid fa-check text-green"></i>
                                    </div>
                                    <span className="text-white">
                                        Eficiencia en gestión documental
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 col-lg-6">
                            <img className="image-process" width="80%" src={Process} alt="Proceso B2B" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col text-center">
                    <i className="fa-solid fa-chevron-down fs-1 text-green animate__animated animate__shakeY animate__slow animate__infinite"></i>
                </div>
            </div>
        </>
    )
}

export default Start;