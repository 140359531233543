import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        dataClient: {
            name: '',
            surname: '',
            type_document_id: "",
            num_document: '',
            nick_name: '',
            level_id: "",
            email: '',
            date_birth: '',
            phone_contact: '',
            address: '',
            code: '',
        },
        isLoading: false,
        listTypeDocument: [],
        listLevel: [],
    },
    reducers: {
        reducerForm: (state, action) => {
            state.dataClient[action.payload.key] = action.payload.value
        },
        setListTypeDocument: (state, action) => {
            state.listTypeDocument = action.payload.value;
        },
        cleanData: (state) => {
            state.dataClient = {
                name: '',
                surname: '',
                type_document_id: "",
                num_document: '',
                nick_name: '',
                level_id: "",
                email: '',
                date_birth: '',
                phone_contact: '',
                address: '',
                code: '',
            };
        },
    },
});

export const {
    reducerForm,
    setListTypeDocument,
    cleanData,
} = userSlice.actions;

export default userSlice.reducer;