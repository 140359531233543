import { useState } from "react";
import { questions } from "../fixtures/question";
import CardQuestion from "./CardQuestion";
import { useResize } from "../hooks/useResize";
import { Accordion } from "react-bootstrap";


const FrequestQuestions = () => {
    const [activeQuestion, setActiveQuestion] = useState(questions[0]);
    const { windowSize } = useResize();

    return (
        <div className="row" id="faqs">
            <div className="col bg-light-green pb-3">
                <div className="row">
                    <div className="col-10 mx-auto mt-5 mb-3">
                        <h2>Preguntas Frecuentes</h2>
                    </div>
                </div>
                <div className="row">
                    {
                        windowSize.width <= 991.98
                            ?
                            <div className="col-10 mx-auto accordion-question">
                                <Accordion defaultActiveKey="0">
                                    {
                                        questions.map(question => <Accordion.Item eventKey={question.id} key={question.id}>
                                            <Accordion.Header onClick={() => setActiveQuestion(question)} as="h3">
                                                <i className={`fa-solid fa-circle fs-6 me-2 ${question.id === activeQuestion.id ? "text-green" : "text-light-green"}`}></i>
                                                <span className="header">{question.name}</span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {question.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>)
                                    }
                                </Accordion>
                            </div>
                            :
                            <div className="col-10 mx-auto" style={{ position: "relative", minHeight: "400px" }}>
                                <div className="block-questions">
                                    {
                                        questions.map(question => <CardQuestion
                                            key={question.id}
                                            name={question.name}
                                            active={activeQuestion.id === question.id}
                                            onClick={() => setActiveQuestion(question)}
                                        />)
                                    }

                                </div>
                                <div className="block-answers">
                                    <div className="row">
                                        <div className="col">
                                            <b>{activeQuestion.name}</b>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            {activeQuestion.answer}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FrequestQuestions;