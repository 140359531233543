import { useEffect, useRef } from "react";

import { useResize } from "../hooks/useResize";
import LogoSortom from "../../assets/logoSortom.png";

const Footer = () => {
    const canvasRef = useRef(null);
    const { windowSize } = useResize();

    const drawBackground = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        ctx.beginPath();
        ctx.moveTo(0, 10);
        ctx.lineTo(0, canvasHeight);
        ctx.lineTo(canvasWidth, canvasHeight);

        if (canvasWidth <= 576) ctx.lineTo(canvasWidth, canvasHeight * .1);
        else ctx.lineTo(canvasWidth, canvasHeight * .2);

        ctx.fillStyle = '#198754';
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();
        ctx.moveTo(0, 0);
        if (canvasWidth <= 576) ctx.lineTo(canvasWidth, canvasHeight * .085);
        else ctx.lineTo(canvasWidth, canvasHeight * .185);
        ctx.strokeStyle = '#8A83E7';
        ctx.stroke();
        ctx.closePath();
    }

    useEffect(() => {
        drawBackground();
    }, [windowSize.width]);

    const heightCanvas = () => {
        if (windowSize.width < 576) return 688;
        if (windowSize.width > 992) return 560;
        return 700;
    }

    return (
        <div className="footer">
            <canvas className="canvas-footer" ref={canvasRef} width={windowSize.width} height={heightCanvas()}></canvas>
            <div className="content">
                <div className="row">
                    <div className="col-12 col-lg-10 mx-auto">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-3">
                                <h2 className="text-white text-center mb-3">Potencia tu negocio</h2>
                                <div className="circle-footer-bench mb-2">
                                    <img className="d-inline-block me-2" src={LogoSortom} alt="Logo Sortom" height={80} />
                                    <h1 className="font-lilita-one text-green">Sortom</h1>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 col-lg-2 mx-auto text-white font-lato ps-5 mt-3">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="fw-bold">Acerca de</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <a className="link-footer" href="/#home">Inicio</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <a className="link-footer" href="/#home">Funcionalidades</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <a className="link-footer" href="/#faqs">FAQs</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <a className="link-footer" href="/#histories">Historias</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <a className="link-footer" href="/#contact-us">Contáctanos</a>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-6 col-md-3 col-lg-2 mx-auto text-white font-lato ps-5 mt-3">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="fw-bold">Privacidad</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Privacidad
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Política
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Pagos
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Términos
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-6 col-md-3 col-lg-2 mx-auto text-white font-lato ps-5 mt-3">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="fw-bold">Contáctanos</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        sortomoficial@gmail.com
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <div className="social-media">
                                            <i className="fa-brands fa-instagram"></i>
                                        </div>
                                        <div className="social-media">
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </div>
                                        <div className="social-media">
                                            <i className="fa-brands fa-linkedin-in"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <h6 className="text-white text-center">© Copyright {new Date().getFullYear()} <b>Sortom</b> All rights reserved</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;