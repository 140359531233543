import { useState } from "react";

const animations = [
    "animate__bounce",
    "animate__flash",
    "animate__pulse",
    "animate__rubberBand",
    "animate__shakeX",
    "animate__shakeY",
    "animate__headShake",
    "animate__swing",
    "animate__tada",
    "animate__wobble",
    "animate__jello",
    "animate__heartBeat",
];

const CardFunctionality = ({ title, subtitle, description, src, className }) => {
    const [hover, setHover] = useState(false);
    const [animation, setAnimation] = useState("");

    const handleMouseOver = () => {
        var randomNumber = Math.random();
        var number = Math.floor(randomNumber * 12);
        setAnimation(animations[number]);
        setHover(true);
    };

    const handleMouseOut = () => {
        setHover(false);
    };

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            className={`card-functionality ${className}`}
        >
            <div className="row h-100">
                <div className="col-4 col-lg-3 abs-center">
                    <img className={`animate__animated ${hover ? animation : ""}`} src={src} alt={title} />
                </div>
                <div className="col-8 col-lg-9">
                    <h6 className="fw-bold">{title}</h6>
                    <h6>{subtitle}</h6>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default CardFunctionality;