import {
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';

import LandingPage from '../app/views/LandingPage';

const AppRouter = () => {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/"> <LandingPage /> </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default AppRouter;