import { useEffect, useState } from "react";

export const useThrowAnimation = (animationInterval, animationName) => {
    const [isAnimationActive, setAnimationActive] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setAnimationActive(false);
            setTimeout(() => {
                setAnimationActive(true);
            }, 5000);
        }, animationInterval);

        return () => clearInterval(intervalId);
    }, [animationInterval]);

    return { animation: isAnimationActive ? animationName : "" };
}
