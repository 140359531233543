import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { useResize } from "../hooks/useResize";

const StepsCreateDocument = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const history = useHistory();
    const { windowSize } = useResize();
    const videoRef = useRef();

    const parameterizeDocumentTime = 0;
    const createDocumentTime = 76.5;
    const exportDocumentTime = 137;
    const sendSignatureTime = 146;

    const playAt = (position) => {
        if (videoRef.current) {
            videoRef.current.currentTime = position;
            videoRef.current.play();
        }
    };

    const updateStep = (number) => {
        if (number > 3 || number < 0) return;
        setCurrentStep(number);
        switch (number) {
            case 0: // Parametriza documentos
                playAt(parameterizeDocumentTime);
                break;
            case 1:
                playAt(createDocumentTime); // Crea documentos
                break;
            case 2:
                playAt(exportDocumentTime); // Exporta documentos
                break;
            case 3:
                playAt(sendSignatureTime); // Envía a tus destinatarios
                break;

            default:
                playAt(parameterizeDocumentTime);
                break;
        }
    }


    useEffect(() => {
        const handleTimeUpdate = () => {
            const currentTime = videoRef.current?.currentTime;

            setCurrentStep(
                currentTime < createDocumentTime ? 0
                    : currentTime > createDocumentTime && currentTime < exportDocumentTime ? 1
                        : currentTime > exportDocumentTime && currentTime < sendSignatureTime ? 2 : 3
            );
        }

        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, []);

    return (
        <>
            <hr style={{ border: "2px solid #F2B641", paddingBottom: "0px" }} />
            <div className="section-steps-document">
                <div className="background-steps-document"></div>
                <div className="container-fluid pe-0" style={{ position: "absolute", left: 0, top: 0 }}>
                    <div className="row mt-4">
                        <div className="col">
                            <h4 className="font-lato text-center text-white px-2" id="documents">Con Sortom puedes <b>parametrizar</b> documentos, <b>crear</b>, <b>exportar</b> y <b>enviar</b> firma electrónica a tus destinatarios</h4>
                        </div>
                    </div>
                    <div className="row mt-4 pe-0">
                        {
                            windowSize.width > 744
                                ?
                                <>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="video-section">
                                            <video ref={videoRef} className="d-block mx-auto rounded-3" width="100%" controls>
                                                <source src="https://storage.googleapis.com/npg-storage.appspot.com/landing/videos/Pasos%20documento%20landing-1.mp4" type="video/mp4" controls autoPlay />
                                                Tu navegador no soporta el tag de video.
                                            </video>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">

                                        <div className="stepper">
                                            <div role="button" onClick={() => updateStep(0)} className={`step ${currentStep === 0 ? "active" : ""}`}>
                                                <div className="step-number">1</div>
                                                <div className="step-content">
                                                    <h5>Parametrización de documentos</h5>
                                                    <p> Define los tipos de documentos y usuarios para crear tus plantilllas.</p>
                                                </div>
                                            </div>
                                            <div role="button" onClick={() => updateStep(1)} className={`step ${currentStep === 1 ? "active" : ""}`}>
                                                <div className="step-number">2</div>
                                                <div className="step-content">
                                                    <h5>Crea documentos</h5>
                                                    <p>Con la parametrización o plantilla de tus documentos, puedes comenzar creando uno, definir los actores involucrados, configurar las opciones de envío de firma y responder a los diálogos de manera rápida y eficiente</p>
                                                </div>
                                            </div>
                                            <div role="button" onClick={() => updateStep(2)} className={`step ${currentStep === 2 ? "active" : ""}`}>
                                                <div className="step-number">3</div>
                                                <div className="step-content">
                                                    <h5>Previsualiza el documento</h5>
                                                    <p>Una vez que hayas completado todos los datos hasta este punto, podrás generar el PDF y obtener una previsualización exacta de lo que tus clientes van a firmar.</p>
                                                </div>
                                            </div>
                                            <div role="button" onClick={() => updateStep(3)} className={`step ${currentStep === 3 ? "active" : ""}`}>
                                                <div className="step-number">4</div>
                                                <div className="step-content">
                                                    <h5>Firma y exporta</h5>
                                                    <p>Una vez que todo esté preparado, puedes enviar el documento a tus clientes para su firma. Incluso tendrás la capacidad de monitorear si han abierto el enlace, si han firmado y mucho más. Obtén más detalles aquí.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                                :
                                <>
                                    <div className="row">
                                        <div className="col-10 mx-auto">
                                            <div className="stepper">
                                                {
                                                    currentStep === 0
                                                    &&
                                                    <div className="step mobile animate__animated animate__fadeIn">
                                                        <div className="step-number">1</div>
                                                        <div className="step-content">
                                                            <h5>Parametriza documentos</h5>
                                                            <p>Define tus propios tipos de documentos con los tipos de persona que puede aceptar, cuestionario y plantilla automatizada.</p>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    currentStep === 1
                                                    &&
                                                    <div className="step mobile animate__animated animate__fadeIn">
                                                        <div className="step-number">2</div>
                                                        <div className="step-content">
                                                            <h5>Crea documentos</h5>
                                                            <p>Con la parametrización o plantilla de tu documentos puedes pasar a crear uno, define sus actores, configura las opciones de envío de firma y responde a los diálogos.</p>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    currentStep === 2
                                                    &&
                                                    <div className="step mobile animate__animated animate__fadeIn">
                                                        <div className="step-number">3</div>
                                                        <div className="step-content">
                                                            <h5>Exporta el formato</h5>
                                                            <p>Si has completado los datos hasta este punto, podrás generar el PDF y hacer una previsualización exacta de lo que van a firmar tus clientes.</p>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    currentStep === 3
                                                    &&
                                                    <div className="step mobile animate__animated animate__fadeIn">
                                                        <div className="step-number">4</div>
                                                        <div className="step-content">
                                                            <h5>Envía</h5>
                                                            <p>Con todo listo ya puedes enviar el documento a tus clientes para que lo puedan firmar, incluso puede monitorear si abrieron el enlace, si han firmado y más. Puedes ver más información aquí.</p>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pe-0">
                                        <div className="col-1 px-0 abs-center">
                                            <div className="handler-step" onClick={() => updateStep(currentStep - 1)}>
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div className="video-section">
                                                <video ref={videoRef} className="d-block mx-auto rounded-3" width="100%" controls>
                                                    <source src="https://storage.googleapis.com/npg-storage.appspot.com/landing/videos/Pasos%20documento%20landing-1.mp4" type="video/mp4" controls autoPlay />
                                                    Tu navegador no soporta el tag de video.
                                                </video>
                                            </div>
                                        </div>
                                        <div className="col-1 px-0 abs-center">
                                            <div className="handler-step right" onClick={() => updateStep(currentStep + 1)}>
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>

                    <div className="row mt-4">
                        <div className="col text-center">
                            <a href="/#contact-us" className="d-inline-block btn animated-button bg-orange text-white fs-4 rounded-3" >
                                <i className="fa-solid fa-gift me-2"></i>
                                Contáctanos
                            </a>
                            <a className="d-inline-block ms-2 btn bg-white text-green fs-4 rounded-3" href="https://wa.me/573133866002">
                                <i className="fa-brands fa-whatsapp me-2"></i>
                                Ventas
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ border: "2px solid #F2B641" }} />
        </>
    )
}

export default StepsCreateDocument;